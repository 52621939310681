<template>
  <v-container>
    <!-- ACTION -->
    <!-- <v-textarea
      class="text-reply"
      v-model="SendReply"
      :label="$t('composed.writeReply')"
      ref="reply"
      solo
      dense
      counter
      height="90"
      color="#df6464"
      @keyup="changeTextStyle"
      hide-details
      background-color="grey lighten-3"
    ></v-textarea>
    <v-file-input
      v-model="files"
      @change="onChangeFile"
      class="reply-file"
      color="#df6464"
      :placeholder="$t('composed.attachment')"
      multiple
      small-chips
    ></v-file-input>
    <v-btn class="reply-btn" color="#00cba3" @click="sendNewReply()">Send</v-btn> -->

    <v-row class="myCard myCustomScrollbar" ref="myCardRow" id="myCardRow">
      <v-col id="printAll" ref="chatContainer">
        <v-row
          outlined
          elevation="2"
          class="mb-5"
          v-for="(i, mainIndex) in Reply"
          :key="mainIndex"
          :id="`printCard${mainIndex}`"
        >
          <v-col cols="12">
            <v-card :style="i.SenderName == Name ? 'float:right;' : 'float: left;'">
              <v-container
                :style="
                  i.SenderName == Name
                    ? 'background-color: #f5fff5;'
                    : i.system_from != 'AWS' && i.SenderName != Name
                    ? 'background-color: #fde499'
                    : 'background-color: #f6fafe'
                "
              >
                <!-- HEADER -->
                <v-row class="mb-3">
                  <v-col>
                    <span style="float: right"> Japan Date/Time: {{ getJPTime(i.DateCreated,i.system_from) }}</span>
                    <br>
                    <span style="float: right"> Philippines Date/Time: {{ getTimePh(i.DateCreated,i.system_from) }}</span>
                    <br>
                    <span style="color: grey">{{ $t("composed.to") }}:</span>
                    <span
                      >{{ !i.ReplyTo ? i.To : i.ReplyTo }} ({{
                        !i.ReplyTo ? i.ToEmail : i.ToEmail
                      }})</span
                    >
                    <br />
                    <!-- <span style=" float: right">{{
                  getTime(i.DateCreated)
                }}</span> -->
                    <span style="coor: grey">{{ $t("composed.cc") }}:</span>
                    <!-- NOTE CC -->
                    <span>{{ i.Cc == "()" ? "" : i.Cc }}</span>
                    <br />
                    <v-menu
                      v-if="hindeContent"
                      left
                      offset-y
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="float: right; cursor: pointer"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-printer</v-icon
                        >
                      </template>

                      <v-list>
                        <v-list-item v-for="(val, index) in printStatus" :key="index">
                          <v-list-item-title
                            @click="
                              val.title == 'Print Conversation'
                                ? print(`printCard${mainIndex}`)
                                : print('printAll')
                            "
                            style="cursor: pointer"
                            >{{ val.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <span style="color: grey">{{ $t("composed.from") }} </span>
                    <span>{{ i.SenderName }} ({{ i.SenderEmail }})</span>
                    <br />
                    <span style="color: grey">{{ $t("composed.subject") }}: </span
                    ><span>{{ i.Subject }}</span>
                    <span style="float: right">
                      {{ $t("composed.replyDeadline") }} :
                      {{ i.DueDate ? getDueDate(i.replyDue) : "No Due Date" }}</span
                    >
                  </v-col>
                </v-row>
                <v-divider v-if="!i.InquiryType"></v-divider>
                <!-- NAV -->
                <v-row
                  v-if="i.InquiryType"
                  style="background-color: #f7f7f7"
                  class="pl-1 pr-1"
                >
                  <v-col>
                    <span style="color: black; font-size: 13px"
                      >{{ $t("composed.inquiryType") }}:
                    </span>
                    <span style="font-size: 13px">{{ i.InquiryType }}</span>
                  </v-col>
                  <v-col>
                    <span style="font-size: 13px; float: right">{{ i.InquiryItem }}</span>
                    <span style="color: black; font-size: 13px; float: right"
                      >{{ $t("composed.inquiryItem") }}:
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  :style="
                    i.SenderName == Name
                      ? 'background-color: #f5fff5;'
                      : i.system_from != 'AWS' && i.SenderName != Name
                      ? 'background-color: #fde499'
                      : 'background-color: #f6fafe'
                  "
                  class="pl-1 pr-1 mt-n6"
                >
                  <v-col cols="6"
                    ><span style="color: black; font-size: 13px"
                      >{{ $t("composed.customerCode") }}:
                    </span>

                    <span style="font-size: 13px">{{
                      !i.CustomerCode ? Reply[0].CustomerCode : i.CustomerCode
                    }}</span>
                  </v-col>
                  <v-col cols="6">
                    <span style="font-size: 13px; float: right">{{
                      i.RuleBookNumber
                    }}</span>
                    <span style="color: black; font-size: 13px; float: right"
                      >{{ $t("composed.rulebookNumber") }}:
                    </span></v-col
                  >
                </v-row>
                <!-- CONTENT -->
                <v-row>
                  <v-col>
                    <span style="color: black; font-size: 13px"
                      >{{ $t("composed.bodyMessage") }}:</span
                    ><br />
                    <span style="font-size: 17px" v-html="i.MessageContent"> </span
                    ><br /><br />
                    <div v-if="hindeContent">
                      <div v-if="i.Attachment">
                        <span style="color: black; font-size: 13px"
                          >{{ $t("composed.attachment") }}: </span
                        ><br />
                        <div
                          v-for="(val, index) in splitAttachment(i.Attachment)"
                          :key="index"
                        >
                          <v-spacer></v-spacer>
                          <span
                            @click="downloadPdf(mainIndex, val.OriginalName)"
                            style="color: #1976d2; cursor: pointer; font-size: 13px"
                            >{{ val.ClientName }}
                          </span>
                        </div>
                      </div>
                      <br />
                    </div>
                    <v-divider></v-divider>
                    <span>{{ i.system_from ? `system:${i.system_from}` : "" }}</span>
                  </v-col>
                </v-row>
                <!-- NO RECORD -->
                <!--   v-if="showReply != true && mainIndex == 0"
                @click.prevent="showReplyCol" -->

                <!-- ACTION -->
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-btn
          style="position: sticky; bottom: 0"
          v-if="hindeContent"
          block
          color="primary"
          dense
          @click="replyShow(), (replyBtn = !replyBtn), replyForm"
          ><v-icon style="color: #cf1717">mdi-reply</v-icon
          >{{ $t("composed.writeReply") }}</v-btn
        >
        <!-- <v-row>
          <div v-if="hindeContent">
            <v-col cols="12" style="background-color: blue"
              ></v-col
            >
          </div>
        </v-row> -->
      </v-col>
      <!-- LOADING SKELETON -->
      <v-skeleton-loader
        v-if="!Email"
        class="cardHeight"
        elevation="2"
        type="card-avatar, article,article,article,list-item-two-line, actions"
      ></v-skeleton-loader>
    </v-row>

    <!-- NOTE start -->
    <v-dialog
      v-model="replyBtn"
      width="600"
      hide-overlay
      persistent
      :content-class="'chat-reply'"
    >
      <v-card class="card_dragable">
        <v-card-title
          :style="!Email ? 'display:none;' : 'background-color: #df6464;cursor: move;'"
          class="py-1 px-5"
        >
          <span v-if="showReplyText != true" style="color: white"> Make a Reply </span>
          <span v-else style="color: white"> Re: {{ replySubject }} </span>
          <v-spacer></v-spacer>
          <!-- <v-icon v-if="showReplyText != true" color="white" @click="replyShow()">mdi-reply</v-icon> -->
          <v-icon
            v-if="(showReplyText = true)"
            color="white"
            @click="replyClose(), (replyBtn = !replyBtn)"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-text :class="showReplyText != true ? 'pa-3 d-none' : 'pa-3'">
          <v-container class="pa-0">
            <v-combobox
              v-model="sendReplyTo"
              :items="sendReplyTo"
              label="To"
              dense
              outlined
              multiple
              chips
              hide-details
              class="pt-2"
            />
            <v-combobox
              v-model="sendReplyCc"
              :items="sendReplyCc"
              multiple
              chips
              label="Cc"
              dense
              outlined
              hide-details
              class="pt-2"
            />
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="date"
                  label="Due"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  class="pt-2"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :allowed-dates="allowedDatesFn"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <v-textarea
              class="text-reply pt-2 pb-0"
              v-model="SendReply"
              :label="$t('composed.writeReply')"
              ref="reply"
              solo
              outlined
              dense
              counter
              height="29vh"
              color="#df6464"
              @keyup="changeTextStyle"
              hide-details
            ></v-textarea>
            <file-pond
              class="reply-file pt-2"
              credits="false"
              outline
              v-model="tempFile"
              ref="pond"
              :onaddfile="onChangeFile"
              :label-idle="$t('composed.attachment')"
              allow-multiple="true"
              dropOnPage="true"
            />
            <v-row>
              <v-col>
                <v-btn
                  block
                  class="reply-btn pb-0"
                  color="success"
                  @click="sendNewReply()"
                  >Send</v-btn
                >
              </v-col>
              <v-col>
                <v-btn width="100%" outlined @click.native="SaveDraft">
                  {{ $t("composed.clear") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- WAITING DIALOG -->
    <v-dialog
      v-model="waitingDialog"
      v-if="waitingDialog"
      persistent
      max-width="200px"
      transition="fade"
    >
      <v-card class="pa-3 text-center">
        <b style="font-size: 15px; font-family: Arial, Helvetica, sans-serif">{{
          language == "en" ? "Plase Wait..." : "お待ちください..."
        }}</b>
        <v-img src="../../assets/loading.gif" width="50" height="50" style="margin: auto">
        </v-img>
      </v-card>
    </v-dialog>
    <!-- NOTE end -->
    <!-- DRAFT DIALOG -->
    <v-dialog v-model="draftDialog" v-if="draftDialog" max-width="400">
      <v-card>
        <v-card-title>
          <v-icon>mdi-content-save-edit</v-icon>
          {{ language == "en" ? "Save Draft" : "下書きを保存" }}</v-card-title
        >
        <v-divider></v-divider>
        <v-text-field
          v-model="draftName"
          @keyup.enter="insertDraft"
          solo
          class="mt-5 ml-5 mr-5 mb-n5"
          :placeholder="
            language == 'en' ? 'Enter Draft Name' : 'ドラフト名を入力してください'
          "
        ></v-text-field>
        <v-card-actions>
          <v-btn color="#00cba3" filled block @click="insertDraft">
            {{ language == "en" ? "Save" : "保存する" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import store from "../../store";
import moment from "moment-timezone";
import AWSFILES from "../../functions/Attachment";
import DraftTable from "../../functions/httpRequest/ExternalConnection/DraftTable";
// import HRDSQL from "../../functions/httpRequest/ExternalConnection/hrdSql";
import replyTable from "../../functions/httpRequest/ExternalConnection/ReplyTable";
import confirmationTable from "../../functions/httpRequest/ExternalConnection/ConfirmationTable";
import InquiryStatusTable from "../../functions/httpRequest/ExternalConnection/InquiryStatusTable";
// import UserTable from "../functions/httpRequest/ExternalConnection/UsersTable";
import { notificationTemplate } from "../../functions/emailTemplate";
import notifiy from "../../functions/alert";
import Email from "../../functions/email";
import _ from "lodash";
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// import { filter } from "vue/types/umd";
const FilePond = vueFilePond();
export default {
  // sockets: {
  //   updateReceived: function (socket) {
  //     // console.log(socket);
  //     if (
  //       socket.hrdInquiryReplyId &&
  //       (socket.hrdInquiryReplyTo == store.state.userinfo.FullName ||
  //         socket.hrInquirySenderName == store.state.userinfo.FullName)
  //     ) {
  //       this.received_Reply(socket.hrdInquiryReplyId).then((res) => {
  //         this.Reply.push(res.data);
  //         this.Reply = _.orderBy(this.Reply, "DateCreated", "asc");
  //       });
  //     }
  //     // switch (socket.hrdInquiryStatus) {
  //     //   case "LOGIN":
  //     //     this.$socket.emit("newUpdate", {
  //     //       hrdInquiry: this.id,
  //     //       hrdInquiryStatus: "ONLINE",
  //     //     });
  //     //     this.testArr(socket.hrdInquiry);
  //     //     break;
  //     //   case "LOGOUT":
  //     //     this.LOG_OUT(socket.hrdInquiry);
  //     //     break;
  //     //   case "ONLINE":
  //     //     this.testArr(socket.hrdInquiry);
  //     //     break;
  //     // }

  //     // if (socket.hrdInquiryStatus == "ONLINE") {
  //     //   this.testArr(socket.hrdInquiry);
  //     // }
  //     // this.LOG_OUT(socket.hrdInquiry);
  //     // if (!socket.hrdInquiry) {
  //     //   // CODE INTERVAL TIME
  //     //   this.LOG_OUT(socket.hrdInquiry);
  //     // }
  //   },
  // },
  data() {
    return {
      allowedDates: [],
      holidaySchedule: [],
      date: null,
      menu2: false,
      display: {
        name: "",
        email: "",
      },
      draftName: "",
      draftDialog: false,
      waitingDialog: false,
      replySubject: "",
      sendReplyCc: [],
      sendReplyTo: [],
      showReplyText: false,
      tempFile: [],
      socket: "",
      EmployeeCode: "",
      name: "",
      SendReply: "",
      id: "",
      loadAllData: false,
      showReply: false,
      dialog: false,
      hindeContent: true,
      Reply: {},
      toStore: {},
      files: [],
      s3Key: [],
      attachment: [],
      insertId: null,
      replyID: store.state.ReplyID,
      Email: store.state.userinfo.Email,
      Name: store.state.userinfo.FullName,
      printStatus: [{ title: "Print All Conversation" }, { title: "Print Conversation" }],
      replyBtn: false,
      replyForm: false,
    };
  },
  components: {
    FilePond,
  },
  created() {
    // GET SOCKET IO

    let { id } = this.$route.params;
    if (id) this.$store.commit("GET_REPLY_ID", id);
    else this.$store.commit("GET_REPLY_ID", null);

    this.socket = this.$io;
    // CALL REPLY FUNCTION
    this.getReply();
    // this.GetDuedate();
    this.getDefaultDate();
    // this.allowedDates = this.generateDatesArray();
    // GET REALTIME REPLY
    // this.socket.on("sendReplyTotoClient", (data) => {
    //   data.SenderName = data.ReplyPerson;
    //   this.Reply.unshift(data);
    // });
    // CHECK USER TYPE
    if (store.state.userinfo.EmployeeNumber) {
      this.EmployeeCode = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.EmployeeCode = store.state.userinfo.EmployeeCode;
    }
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    // filteredDates() {
    //   // Filter out Wednesdays from the allowed dates
    //   return this.allowedDates.filter((date) => moment(date).day() !== 3); // 3 is the day index for Wednesday
    // },
  },
  watch: {
    Reply(){
      this.scrollToBottom()
      // let a = document.createElement('a')
      // a.setAttribute('href',`#printCard${val.length-1}`)
      // a.setAttribute('target','_self')
      // a.click()
      // a.remove()
    },
    // GET CONFIRMATION ID IN PARAMETER
    $route(to, from) {
      this.id = from.params.id;
      console.log("changed route");
      this.scrollToBottom()
      // setTimeout(() => {
      //   console.log("scroll function");
      //   let cardRow = document.querySelector("#myCardRow");
      //   if (cardRow) {
      //     cardRow.scrollTop = cardRow.scrollHeight;
      //   }
      // }, 1000);
      // SCROLL NEXT
    },
    // CHECK FOR ROUTER PARAM EVERY CHANGES
    "$route.params.id"() {
      this.getReply();
      this.loadAllData = false;
    },
    replyID() {
      console.log(this.replyID, "watch fro mreply");
    },
  },
  methods: {
    getDefaultDate() {
      // let today = moment().format("YYYY-MM-DD");
      let currentDate = moment(); // Get the current date
      let defaultDate = currentDate.add(1, "days"); // Add 1 day to the current date

      // If the default date is Wednesday, skip to the next day
      if (defaultDate.day() === 3) {
        defaultDate.add(1, "days");
      }

      // If the default date is Saturday (6) or Sunday (0), move it to Monday (1)
      if (defaultDate.day() === 0 || defaultDate.day() === 6) {
        defaultDate.add(1, "days");
        if (defaultDate.day() === 0) {
          defaultDate.add(1, "days");
        }
      }

      this.date = defaultDate.format("YYYY-MM-DD");
      //  defaultDate;
    },
    allowedDatesFn(date) {
      const day = moment(date).day();
      return day !== 3 && day !== 0 && day !== 6;
    },
    // GetDuedate() {
    //   // console.log(this.information.TypeOfConfirmation)
    //   HRDSQL.Calendar().then((res) => {
    //     this.holidaySchedule = res.data;
    //   });
    //   console.log(this.holidaySchedule);
    // },
    SaveDraft() {
      this.draftDialog = true;
      this.toStore.UserID = this.userID;
      this.toStore.DateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
    },
    //TODO Reply
    async getEmaillToCc() {
      this.replySubject = "";
      this.sendReplyTo = [];
      this.sendReplyCc = [];
      // await confirmationTable
      //   .GetSpecificConfirmation(store.state.ReplyID)
      //   .then((res) => {
      //     for (let i = 0; i < res.data.length; i++) {
      //       const element = res.data[i];
      //       this.sendReplyTo.push(element.SenderEmail);
      //       this.sendReplyCc.push(element.CcEmail);
      //       this.replySubject = element.Subject;
      //     }

      //     this.sendReplyCc.push(this.Email);
      //   });

      await confirmationTable.GetSpecificConfirmation(store.state.ReplyID).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          if (element.SenderEmail == this.Email) {
            this.sendReplyTo = element.ToEmail.split(",");
          } else {
            this.sendReplyTo = element.SenderEmail.split(",");
          }
          this.sendReplyCc = element.CcEmail.split(",");
          // this.replySubject = element.Subject;
          this.replySubject = element.Subject;

          this.sendReplyCc = element.CcEmail == "" ? [] : element.CcEmail.split(",");
          // this.replySubject = element.Subject;
          this.replySubject = element.Subject;
        }
      });
    },
    replyShow() {
      this.showReplyText = true;
      this.getEmaillToCc();
      this.hindeContent = false;
    },
    replyClose() {
      this.showReplyText = false;
      this.hindeContent = true;
      // this.hindeContent = false
    },
    //*****************************//
    //  GET REPLY OF CONFIRMATION  //
    //*****************************//
    // NOTE Reply
    getReply() {
      replyTable.GetReply(this.$route.params.id).then((res) => {
        console.log(res.data);
        // console.log()
        // this.Reply = res.data;
        // console.log(res.data, "629");
        const formattedData = [];
        // let filterEmail = res.data.filter((rec) => {
        //   let toEmail;
        //   try {
        //     // Try parsing the JSON
        //     toEmail = JSON.parse(rec.ToEmail);
        //   } catch (error) {
        //     // If parsing fails, treat ToEmail as a string
        //     toEmail = rec.ToEmail;
        //   }

        //   // If toEmail is an array, check if it includes this.Email
        //   // if (Array.isArray(toEmail)) {
        //   //   if (toEmail.includes(this.Email)) {
        //   //     return rec;
        //   //   }
        //   // } else {
        //   //   // If toEmail is not an array, directly compare with this.Email
        //   //   if (toEmail === this.Email) {
        //   //     return rec;
        //   //   }
        //   // }
        //   return false;
        // });
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          const ccArray = element.Cc ? element.Cc.split(",") : null;
          const ccEmailArray = element.CcEmail ? element.CcEmail.split(",") : [];

          const formattedArray = ccArray
            ? ccArray.map((name, index) => {
                const email = ccEmailArray[index] || "";
                return `${name.trim()}(${email.trim()})`;
              })
            : null;

          // Join the formatted names and emails
          element.Cc = formattedArray ? formattedArray.join(",") : null;
          element.Cc = element.Cc
            ? element.Cc.split("[").join("").split("]").join("").split('"').join("")
            : null;
          element.CcEmail = ""; // Empty this property as it's already included in Cc

          // Push the formatted element into the new array
          formattedData.push(element);
        }

        // Now `formattedData` contains the desired output
        this.Reply = formattedData;

        this.Reply = _.orderBy(this.Reply, "DateCreated", "asc");

        console.log(this.Reply,"reply")
        this.loadAllData = true;
      });
    },

    //***************//
    //  PRINT REPLY  //
    //***************//

    print(val) {
      this.hindeContent = false;
      this.$htmlToPaper(val);
      this.hindeContent = true;
    },
    //******************************//
    //  HANDLE DOWNLOAD ATTACHMENT  //
    //******************************//

    downloadPdf(Index, val) {
      AWSFILES.download(this.Reply[Index].Path, val);
    },

    //******************************//
    //   SPLIT MULTIPLE ATTACHMENT  //
    //******************************//

    splitAttachment(attachment) {
      let splitAttachment = attachment.split(",");
      if (splitAttachment.length > 1) {
        const splitDate = [];
        for (let x in splitAttachment)
          splitDate.push({
            OriginalName: splitAttachment[x],
            ClientName: splitAttachment[x].split("_")[1],
          });

        return splitDate;
      } else {
        const splitDate = [];
        for (let x in splitAttachment)
          splitDate.push({
            OriginalName: splitAttachment[x],
            ClientName: splitAttachment[x].split("_")[1],
          });

        return splitDate;
      }
    },

    //**********************//
    //   HANDLE FILE UPLOAD //
    //**********************//
    onChangeFile() {
      if (this.attachment.length == this.tempFile.length) return; //RETURN IF ALREADY HAVE ATTACHMENT

      this.files = []; //FILES TO BE SAVE IN S3
      this.attachment = []; //ATTACHMENT NAME TO BE SAVE IN DATABASE

      for (let x in this.tempFile) this.files.push(this.tempFile[x].file);

      // CHECK FOR ATTACHMENT
      if (this.files.length) {
        for (let i = 0; i < this.files.length; i++) {
          const attachment = this.files[i].name;
          this.attachment.push(attachment);
          let previewFile = URL.createObjectURL(this.files[i]);
          // PREVIEW 3 TYPES OF EXTENTION

          window.open(previewFile);
        }
      }
    },

    //***************************//
    //   SENDING REPLY FUNCTION  //
    //***************************//

    async sendNewReply() {
      this.waitingDialog = true;
      // CHECK FOR EMPTY REPLY
      if (this.SendReply == "")
        return this.$toast.error(this.$t("alert.inquiryMessage"), notifiy.alert);
      else {
        // ASSIGN VARIABLE
        for (let i = 0; i < this.Reply.length; i++) {
          const element = this.Reply[i];

          if (element.SenderName == this.name) {
            this.toStore.To == element.To;
          } else {
            this.toStore.To = element.SenderName;
          }
        }

        this.toStore.ConfirmationID = store.state.ReplyID;
        this.toStore.SenderID = this.EmployeeCode;

        this.toStore.ReplyPerson = this.name;
        this.toStore.SenderName = store.state.userinfo.FullName;
        this.toStore.Subject = this.Reply[0].Subject;
        this.toStore.DateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
        this.toStore.ToEmail = this.sendReplyTo.join(",");
        this.toStore.CcEmail = !this.sendReplyCc ? "" : this.sendReplyCc.join(",");
        this.toStore.SenderEmail = store.state.userinfo.Email;
        const SendEmail = async () => {
          await replyTable.GetReply(store.state.ReplyID).then(async (res) => {
            const record = res.data;
            let RefNumber = "";
            let due = "";
            // var SenderEmail = "";

            var ReplyID = "";

            await confirmationTable
              .GetSpecificConfirmation(store.state.ReplyID)
              .then((confirmation) => {
                RefNumber = confirmation.data[0].id;
                due = confirmation.data[0].TypeOfConfirmation;
                // SenderEmail = confirmation.data[0].SenderEmail;

                ReplyID = confirmation.data[0].ConfirmationID;
              });

            const data = {
              Receiver: record[record.length - 1].SenderName,
              ReferenceNumber: RefNumber,
              DocumentNumber: record[0].RuleBookNumber,
              Due: due,
              ReplyContent: record,
              ConfirmationID: ReplyID,
            };

            // this.sendReplyCc.push(this.Email);
            this.replyClose();

            // SEND EMAIL CONFIRMATION
            await Email.send(
              store.state.userinfo.FullName,
              // this.Email,
              "no-reply@hrd-ihs.com", //SENDER EMAIL
              this.sendReplyTo,
              !this.sendReplyCc.length ? "imtrial@hrd-s.com" : this.sendReplyCc,
              await notificationTemplate(data),
              `HRD INQUIRY EMAIL NOTIFICAITON`
              // this.s3Key
            );
          });
          this.replyBtn = false;
          this.toStore = {};
          this.attachment = [];
          this.tempFile = [];
          this.sendReplyTo = [];
          this.sendReplyCc = [];
          this.showReply = false;
          this.files = [];
          this.SendReply = "";
          this.$refs.pond.removeFiles();
          this.showReply = false;
          this.$toast.success(this.$t("alert.replySend"), notifiy.alert);
          this.Reply = _.orderBy(this.Reply, "DateCreated", "asc");
          this.waitingDialog = false;
        };
        // CHECK IF REPY IS WITH ATTACHMENT
        if (this.attachment.length > 0) {
          const dateToday = moment().format("YYYY/MM/DD");
          this.toStore.Attachment = this.attachment;
          this.toStore.Path = `Attachment/${dateToday}`;

          // UPLOAD ATTACHMENT
          AWSFILES.upload(
            null,
            "Attachment",
            this.files,
            (filename) => {
              this.toStore.Attachment = filename;
            },
            (keyName) => {
              this.s3Key = keyName.map((el) => `${this.toStore.Path}/${el}`);
            }
          )
            .then(() => {
              SendEmail(); // Call SendEmail after the upload is complete
            })
            .catch((error) => {
              console.error("Upload failed:", error);
            });
        } else {
          SendEmail(); // Call SendEmail if there are no attachments
        }
        // await SendEmail();s

        //  GET REALTIME REPLYs
        // this.socket.emit("receiveReply", this.toStore, (err) => {
        //   if (err) throw err;
        // });

        // var insertID = null;
        // STORE RECORD IN DATABASE
        await confirmationTable.Update(this.toStore).then();
        this.toStore.replyDue = this.date;
        await replyTable.NewReply(this.toStore).then((res) => {
          this.insertID = res.data.insertId; //FOR REALTIME REPLY
          // this.Reply.push(this.toStore);

          // this.socket.emit("sendReplyTotoClient", {
          //   hrdInquiryReplyId: this.insertID,
          //   hrdInquiryReplyTo: this.toStore.To,
          //   hrInquirySenderName: this.toStore.SenderName,
          //   hrdInquirySeenBy: null,
          //   hrdInquiryStatus: "ConvoId",
          // });
          let store = {
            ...this.toStore,
          };

          // let allRecepients = [
          //   this.toStore.ToEmail.split(" "),
          //   this.toStore.CcEmail.split(" "),
          // ].flat();
          delete store.ToEmail;
          delete store.CcEmail;
          delete store.Cc;
          delete store.TypeOfConfirmation;
          delete store.Status;
          delete store.RuleBookNumber;
          delete store.InquiryType;
          delete store.InquiryItem;
          delete store.Department; //BUKAS
          // for (let email of allRecepients) {
          //   this.socket.emit("sendReplyTotoClient", {
          //     ...store,
          //     email: email,
          //     hrdInquiryStatus: "notificationAlert",
          //   });
          // }
        });
        await this.UpdateInquiryStatus(store.state.ReplyID);

        // SENDING EMAIL FUNCTION

        // if (this.attachment.length === 0) SendEmail();
      }
    },

    //*******************************//
    //    UPDATE INQUIRY STATUS      //
    //*******************************//
    async UpdateInquiryStatus(confirmationID) {
      return InquiryStatusTable.GetSpecific(confirmationID).then((res) => {
        const record = res.data || [];
        const toUpdate = {
          ConfirmationID: confirmationID,
          ReplyDate: moment().format("YYYY-MM-DD"),
          ReplyTime: moment().format("HH:mm:ss"),
          ReplyContent: this.SendReply,
        };

        if (!record.length) return false;
        if (record[0].ReplyContent) return false;

        return InquiryStatusTable.Update(toUpdate);
      });
    },

    //*******************************//
    //  CHANGE TIME AND DATE FORMAT  //
    //*******************************//
    getDueDate(replyDue) {
      return moment(replyDue).format("YYYY-MM-DD");

      // return moment(date).add(1, "d").format("YYYY-MM-DD");
    },
    // getDate(date) {
    //   // return moment(date).format("YYYY-MM-DD hh:mm:ss");
    //   return date;
        // },
    getJPTime(date,system_from){
      console.log(system_from);
      // if(system_from == "AWS"){
      //   return moment(date).format("YYYY-MM-DD HH:mm:ss")
      // }
      // else{
      // return moment(date).add(1,'hour').format("YYYY-MM-DD HH:mm:ss")
      // }
      return moment(date).add(1,'hour').format("YYYY-MM-DD HH:mm:ss")
    },

    getTimePh(date,system_from){
      console.log(system_from);
      // if(system_from == "AWS"){
      //   return moment(date).format("YYYY-MM-DD HH:mm:ss")
      // }
      // else{
      //   return moment(date).format("YYYY-MM-DD HH:mm:ss")
      // }
      return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },
    // moment(date).tz("Asia/Manila").format("YYYY-MM-DD HH:mm:ss").split(" ")[1]
    // moment(date.split(" ")[1]).format("hh:mm:ss"),
    // date.split(" ")[1].replace("-", " "),
    // GET HRD PICTURE

    // getPicture: (val) => `http://hrdapps48:3001/ftp/employee_pictures/0${val}.jpg`,
    // FOR NO IMAGE EMPLOYEE

    // changeImage(event) {
    //   event.target.src = `http://hrdapps48:3001/ftp/employee_pictures/no-image.png`;
    // },
    //***************************//
    //   CLOSE REPLY TIMEOUT     //
    //***************************//

    showReplyCol() {
      this.showReply = true;
      setTimeout(() => this.$nextTick(() => this.$refs.reply[0].focus()), 200);
      setTimeout(() => (this.showReply = false), 100000);
    },
    //***************************//
    //   VALIDATE TEXT FORMAT    //
    //***************************//

    changeTextStyle() {
      this.toStore.MessageContent = this.SendReply.split("\n").join("<br>");
    },
    //*************************************//
    //       SAVE COMPOSED DRAFT          //
    //************************************//

    async insertDraft() {
      const element = this.Reply[0];

      if (element.SenderName == this.name) {
        this.toStore.To == element.To;
      } else {
        this.toStore.To = element.SenderName;
      }

      this.toStore.ConfirmationID = store.state.ReplyID;
      this.toStore.UserID = store.state.userinfo.EmployeeCode;
      this.toStore.Subject = this.Reply[0].Subject;
      this.toStore.DateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
      this.toStore.To = this.sendReplyTo.join(",");
      this.toStore.Cc = !this.sendReplyCc ? "" : this.sendReplyCc.join(",");
      // this.toStore.SenderEmail = store.state.userinfo.Email;
      // CHECK IF NULL DRAFT NAME
      if (!this.draftName)
        return this.$toast.error(this.$t("dialog.draftName"), notifiy.alert);

      this.waitingDialog = true;
      // DELETE AND VALIDATE RECORDS
      delete this.toStore.Department;
      this.toStore.DraftName = this.draftName;
      if (this.customerCode) this.toStore.CustomerCode = this.customerCode;
      if (this.rulebookNumber) this.toStore.RuleBookNumber = this.rulebookNumber;

      // CHECK FOR ATTACHMENT
      if (this.files.length) {
        const dateToday = moment().format("YYYY/MM/DD");
        this.toStore.Path = `Drafts/${dateToday}`; //BUILD S3 PATH OF ATTACHMENT

        // FUNCTION FOR UPLOAD IN S3
        AWSFILES.upload(
          null,
          "Drafts",
          this.files,
          (fileName) => {
            this.toStore.Attachment = fileName;
          },
          () => ClearFunction()
        );
      }

      // CHECK FOR SAME DRAFT NAME IN DATABASE
      DraftTable.checkDraft(this.toStore.UserID, this.draftName).then(async (res) => {
        if (res.data == "No record Found")
          await DraftTable.Insert(this.toStore).then(() => {
            if (this.files.length === 0) ClearFunction();
          });
        else {
          this.$toast.error(this.$t("dialog.duplicate"), notifiy.alert);
          this.waitingDialog = false;
        }
      });

      // ClEAR RECORD FUNCTION
      const ClearFunction = () => {
        this.toStore = {};
        this.files = [];
        this.tempFile = [];
        this.$refs.pond.removeFiles();
        this.arrInquireItem = [];
        this.attachment = [];
        this.content = "";
        this.customerCode = "";
        this.rulebookNumber = "";
        this.toStore.Department = "HRD開発";
        this.draftName = "";
        this.draftDialog = false;
        this.waitingDialog = false;
        this.$toast.success(this.$t("dialog.success"), notifiy.alert);
      };
    },
    scrollToBottom() {
      setTimeout(() => {
        let cardRow = document.querySelector("#myCardRow");
        cardRow.scrollTop = cardRow.scrollHeight;
      }, 1000);
    }

  },
  mounted() {
    this.scrollToBottom()
    // this.$refs.myCardRow.addEventListener("DOMNodeInserted", this.scrollToBottom);

  },
  // beforeDestroy() {
  //   this.$refs.myCardRow.removeEventListener("DOMNodeInserted", this.scrollToBottom);
  // },
};
</script>

<style>
.myCard {
  max-height: calc(96vh - 130px);
}

/* Sample 1 */
.text-reply {
  width: 50vw !important;
}
/* .reply-file {
  width: 50vw;
} */
/* .reply-btn {
  width: 50vw;
} */
.chat-reply {
  /* z-index: 1; */
  height: 580px;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.ReplyButton {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 33px;
}
.myCustomScrollbar {
  max-height: auto; /* Set a max height to enable scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  scroll-behavior: smooth;
}

.myCustomScrollbar::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.myCustomScrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
}
</style>
